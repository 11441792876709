@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?rjhnzb');
  src:  url('fonts/icomoon.eot?rjhnzb#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?rjhnzb') format('truetype'),
    url('fonts/icomoon.woff?rjhnzb') format('woff'),
    url('fonts/icomoon.svg?rjhnzb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-night-65:before {
  content: "\e941";
}
.icon-night-64:before {
  content: "\e942";
}
.icon-night-63:before {
  content: "\e943";
}
.icon-night-62:before {
  content: "\e944";
}
.icon-night-60:before {
  content: "\e945";
}
.icon-night-61:before {
  content: "\e946";
}
.icon-night-58:before {
  content: "\e947";
}
.icon-night-59:before {
  content: "\e948";
}
.icon-night-56:before {
  content: "\e949";
}
.icon-night-57:before {
  content: "\e94a";
}
.icon-night-54:before {
  content: "\e94b";
}
.icon-night-55:before {
  content: "\e94c";
}
.icon-night-52:before {
  content: "\e94d";
}
.icon-night-53:before {
  content: "\e94e";
}
.icon-night-50:before {
  content: "\e94f";
}
.icon-night-51:before {
  content: "\e950";
}
.icon-night-49:before {
  content: "\e951";
}
.icon-night-47:before {
  content: "\e952";
}
.icon-night-48:before {
  content: "\e953";
}
.icon-night-45:before {
  content: "\e954";
}
.icon-night-46:before {
  content: "\e955";
}
.icon-night-43:before {
  content: "\e956";
}
.icon-night-44:before {
  content: "\e957";
}
.icon-night-41:before {
  content: "\e958";
}
.icon-night-42:before {
  content: "\e959";
}
.icon-night-39:before {
  content: "\e95a";
}
.icon-night-40:before {
  content: "\e95b";
}
.icon-night-37:before {
  content: "\e95c";
}
.icon-night-38:before {
  content: "\e95d";
}
.icon-night-35:before {
  content: "\e95e";
}
.icon-night-36:before {
  content: "\e95f";
}
.icon-night-33:before {
  content: "\e960";
}
.icon-night-34:before {
  content: "\e961";
}
.icon-night-31:before {
  content: "\e962";
}
.icon-night-32:before {
  content: "\e963";
}
.icon-night-30:before {
  content: "\e964";
}
.icon-night-28:before {
  content: "\e965";
}
.icon-night-29:before {
  content: "\e966";
}
.icon-night-26:before {
  content: "\e967";
}
.icon-night-27:before {
  content: "\e968";
}
.icon-night-24:before {
  content: "\e969";
}
.icon-night-25:before {
  content: "\e96a";
}
.icon-night-22:before {
  content: "\e96b";
}
.icon-night-23:before {
  content: "\e96c";
}
.icon-night-20:before {
  content: "\e96d";
}
.icon-night-21:before {
  content: "\e96e";
}
.icon-night-18:before {
  content: "\e96f";
}
.icon-night-19:before {
  content: "\e970";
}
.icon-night-16:before {
  content: "\e971";
}
.icon-night-17:before {
  content: "\e972";
}
.icon-night-14:before {
  content: "\e973";
}
.icon-night-15:before {
  content: "\e974";
}
.icon-night-13:before {
  content: "\e975";
}
.icon-night-11:before {
  content: "\e976";
}
.icon-night-12:before {
  content: "\e977";
}
.icon-night-09:before {
  content: "\e978";
}
.icon-night-10:before {
  content: "\e979";
}
.icon-night-08:before {
  content: "\e97a";
}
.icon-night-07:before {
  content: "\e97b";
}
.icon-night-05:before {
  content: "\e97c";
}
.icon-night-06:before {
  content: "\e97d";
}
.icon-night-03:before {
  content: "\e97e";
}
.icon-night-04:before {
  content: "\e97f";
}
.icon-night-01:before {
  content: "\e980";
}
.icon-night-02:before {
  content: "\e981";
}
.icon-day-65:before {
  content: "\e900";
}
.icon-day-63:before {
  content: "\e901";
}
.icon-day-64:before {
  content: "\e902";
}
.icon-day-62:before {
  content: "\e903";
}
.icon-day-60:before {
  content: "\e904";
}
.icon-day-61:before {
  content: "\e905";
}
.icon-day-58:before {
  content: "\e906";
}
.icon-day-59:before {
  content: "\e907";
}
.icon-day-56:before {
  content: "\e908";
}
.icon-day-57:before {
  content: "\e909";
}
.icon-day-54:before {
  content: "\e90a";
}
.icon-day-55:before {
  content: "\e90b";
}
.icon-day-53:before {
  content: "\e90c";
}
.icon-day-52:before {
  content: "\e90d";
}
.icon-day-50:before {
  content: "\e90e";
}
.icon-day-51:before {
  content: "\e90f";
}
.icon-day-48:before {
  content: "\e910";
}
.icon-day-49:before {
  content: "\e911";
}
.icon-day-46:before {
  content: "\e912";
}
.icon-day-47:before {
  content: "\e913";
}
.icon-day-45:before {
  content: "\e914";
}
.icon-day-43:before {
  content: "\e915";
}
.icon-day-44:before {
  content: "\e916";
}
.icon-day-41:before {
  content: "\e917";
}
.icon-day-42:before {
  content: "\e918";
}
.icon-day-39:before {
  content: "\e919";
}
.icon-day-40:before {
  content: "\e91a";
}
.icon-day-37:before {
  content: "\e91b";
}
.icon-day-38:before {
  content: "\e91c";
}
.icon-day-35:before {
  content: "\e91d";
}
.icon-day-36:before {
  content: "\e91e";
}
.icon-day-34:before {
  content: "\e91f";
}
.icon-day-32:before {
  content: "\e920";
}
.icon-day-33:before {
  content: "\e921";
}
.icon-day-30:before {
  content: "\e922";
}
.icon-day-31:before {
  content: "\e923";
}
.icon-day-28:before {
  content: "\e924";
}
.icon-day-29:before {
  content: "\e925";
}
.icon-day-26:before {
  content: "\e926";
}
.icon-day-27:before {
  content: "\e927";
}
.icon-day-25:before {
  content: "\e928";
}
.icon-day-23:before {
  content: "\e929";
}
.icon-day-24:before {
  content: "\e92a";
}
.icon-day-21:before {
  content: "\e92b";
}
.icon-day-22:before {
  content: "\e92c";
}
.icon-day-19:before {
  content: "\e92d";
}
.icon-day-20:before {
  content: "\e92e";
}
.icon-day-17:before {
  content: "\e92f";
}
.icon-day-18:before {
  content: "\e930";
}
.icon-day-16:before {
  content: "\e931";
}
.icon-day-14:before {
  content: "\e932";
}
.icon-day-15:before {
  content: "\e933";
}
.icon-day-12:before {
  content: "\e934";
}
.icon-day-13:before {
  content: "\e935";
}
.icon-day-10:before {
  content: "\e936";
}
.icon-day-11:before {
  content: "\e937";
}
.icon-day-08:before {
  content: "\e938";
}
.icon-day-09:before {
  content: "\e939";
}
.icon-day-07:before {
  content: "\e93a";
}
.icon-day-05:before {
  content: "\e93b";
}
.icon-day-06:before {
  content: "\e93c";
}
.icon-day-03:before {
  content: "\e93d";
}
.icon-day-04:before {
  content: "\e93e";
}
.icon-day-01:before {
  content: "\e93f";
}
.icon-day-02:before {
  content: "\e940";
}
