body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  display: none;
}

@keyframes timer_process {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes timer_finish {
  0% {
    transform: translate(8px, 7px) rotate(0deg);
  }
  10% {
    transform: translate(-7px, -8px) rotate(-3deg);
  }
  20% {
    transform: translate(-9px, 0px) rotate(3deg);
  }
  30% {
    transform: translate(0px, 8px) rotate(0deg);
  }
  40% {
    transform: translate(7px, -7px) rotate(3deg);
  }
  50% {
    transform: translate(-7px, 8px) rotate(-3deg);
  }
  60% {
    transform: translate(-9px, 7px) rotate(0deg);
  }
  70% {
    transform: translate(8px, 7px) rotate(-3deg);
  }
  80% {
    transform: translate(-7px, -7px) rotate(3deg);
  }
  90% {
    transform: translate(8px, 8px) rotate(0deg);
  }
  100% {
    transform: translate(7px, -8px) rotate(-3deg);
  }
}
